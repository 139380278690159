/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode } from 'react'

import style from '@styles/Button.module.scss'
import classNames from 'classnames'
import Link from 'next/link'

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  children?: ReactNode
  color?: 'primary' | 'secondary'
}

const Button = ({ children, color = 'primary', ...rest }: Props) => {
  return (
    <button
      className={classNames(style.container, { [style.secondary]: color === 'secondary' })}
      {...rest}
    >
      {children}
    </button>
  )
}

interface LinkProps extends React.ComponentProps<typeof Link> {
  color?: 'primary' | 'secondary'
}
const LinkButton = ({ color, ...props }: LinkProps) => (
  <Link passHref {...props}>
    <a className={classNames(style.container, { [style.secondary]: color === 'secondary' })}>
      {props.children}
    </a>
  </Link>
)

export { Button, LinkButton }
